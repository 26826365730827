<template>
  <div id="placepozniej">
    <div class="container-fluid px0">
      <no-ssr>
        <iframe
          class="iframe"
          src="https://www.placepozniej.payu.pl/"
          frameborder="no"
          width="100%"
          height="100vh"
          >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </no-ssr>
    </div>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'

export default {
  components: {
    'no-ssr': NoSSR
  },
  metaInfo () {
    return {
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ],
      title: 'Placepozniej'
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  height: 99.5vh;
}
</style>
